import React, { useEffect, useState } from 'react';
import Styles from './Login.module.css';
import { useLocation, useNavigate   } from "react-router-dom";
import axios from 'axios';
import { auth, storage, toaster, baseURL, siteLogo, backgroundColor, backgroundWithOpacity } from '../functions/O3developer';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import error from '../json/error.json';

function Login() {
    const { state } = useLocation();
    const [loginState, setLoginState] = useState({
      username: '',
      password: '',
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const formHandler = async (event) => {
      event.preventDefault();
      if (!loading) {
      if (loginState.username == "")  return toaster("error", "The username field cannot be empty");
      if (loginState.password == "")  return toaster("error", "The password field cannot be empty");
      if (loginState.username.length < 2)  return toaster("error", "Invalid username supplied ");
      if (loginState.password.length < 2)  return toaster("error", "Invalid password supplied ");
      setLoading(true);
      axios.defaults.headers.common = {'Content-Type': 'application/json'}
      await axios.post(baseURL+"/user/login",  loginState).then((response) => {
          if (response.data.code !== 200 && response.data.status == "failed") {
            setLoading(false);
            return toaster("error", response.data.message);
          } else if (response.data.code == 200 && response.data.status == "success") {
            setLoading(false);
              if (storage("save", "data", response.data.user, 'localStorage') == true) return navigate("/");
          } else {
            setLoading(false);
          }
        }).catch((e)=>{
            // console.log(e);
            setLoading(false);
            alert(error.whoops)
            return e;
        })
      }
    }

      useEffect(() => { 
        var body = document.getElementById("body-tag");
        body.classList.add("auth-bg");
        
        if (auth() == true){
         return navigate("/")
      } else {
        if (state && state.message !== '' && state.message !== null) {
            return toaster('success', state.message);
        }
      } } , []);

  return (
    <div>

<ToastContainer /> 

<div className='pt-5 my-5'>
<div className="container">
        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5" style={{ padding: 0 }}>


<div className={"card card-style " + Styles.login_card }>


<div className="content">


<div>
<h1 className="font-25 float-start">Sign In</h1>
<img src={siteLogo} style={{  width: "80px" }} className="float-end"/>
</div>


<br/>
<p style={{ marginBottom: "20px", paddingTop: "16px" }}>
Login to your account to access our services.
</p>



<form method='post' onSubmit={formHandler}>

<div className="input-style has-borders has-icon validate-field mb-4">
<i className="fas fa-user"></i>
<input type="text" value={loginState.username} onChange={e => setLoginState({...loginState, username: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter your username" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Username</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<div className="input-style has-borders has-icon validate-field mb-4">
<i className="fa fa-key"></i>
<input type="password" value={loginState.password} onChange={e => setLoginState({...loginState, password: e.target.value})} id="pass" className="form-control validate-password shadow-bg-m shadow-s" placeholder="Enter your password" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Password</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>





<button disabled={loading}
className={"btn btn-full rounded-sm btn-block shadow-l mb-3 mt-4 shadow-bg-m shadow-s " + Styles.login_btn }
style={{background: loading ? backgroundWithOpacity : backgroundColor }}
>
  { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 { loading ? '' : 'Login' }


</button>

</form>




<div className="row pt-2 mb-2">
<div className="col-6 text-start">
<Link to="/forgot-password" className="color-highlight">Forgot Password?</Link>
</div>
 <div className="col-6 text-end">
<Link to='/register' className="color-highlight"> Create Account </Link>
</div>
</div>



</div>
        </div>
</div>
</div>
</div>

</div>





      </div>

  )
}

export default Login
