import Styles from './Footer.module.css'
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

function Footer () {
  let location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  
  useEffect(() => {
    window.addEventListener("resize", () => {
    const ismobile = window.innerWidth < 1200;
    if (ismobile !== isMobile) setIsMobile(ismobile);
  }, false);
}, [isMobile])

  const apiLink = () => {
    window.location.href = 'localhost:8000/api/documentation';
  }
  
  return (
      <div>
   
      {/* <div id='footer-bar' className='footer-bar-2 shadow-xl' style={{borderTop: "none"}} 
          hidden={ location.pathname == "/login" || location.pathname == "/register" || 
          location.pathname == "/forgot-password" || location.pathname == "/reset-password" || isMobile ? true : false }>
        <Link to='/profile'><i className='fa fa-user' style={{ color: '#4a89dc' }}></i><span className='font-11'><b>Profile</b></span></Link>
        <a href='#' onClick={apiLink}><i className='fas fa-code' style={{ color: '#fe7704' }}></i><span className='font-11'><b>Developers API</b></span></a>
        <Link to='/' className={'active-nav ' + Styles.FDashboard}><i className='fab fa-microsoft'></i><span className='font-11'><b>Dashboard</b></span></Link>
        <Link to='/transaction'><i className='fab fa-opencart' style={{ color: '#3f51b5' }}></i><span className='font-11'><b> Transaction </b></span></Link>
        <a href='#' data-menu='menu-main'><i className='fa fa-bars' style={{ color: '#ba2d22' }}></i><span className='font-11'><b>Menu</b></span></a>
      </div> */}









    {/* <div id="footer-bar" className="footer-bar-6"
        hidden={ location.pathname == "/login" || location.pathname == "/register" || 
        location.pathname == "/forgot-password" || location.pathname == "/reset-password" || isMobile ? true : false }>
    <Link to='/profile'> <i className="fa fa-user"></i><span><b>Profile</b></span> </Link>
    <a href='#' onClick={apiLink}><i className="fa fa-code"></i><span><b>Developers API</b></span></a>
        <Link to='/'  className="circle-nav active-nav"><i className="fab fa-microsoft"></i><span>Home</span></Link>
        <Link to='/transaction'><i className="fab fa-opencart"></i><span><b>Transaction</b></span></Link>
        <a href="#" data-menu="menu-main"><i className="fa fa-bars"></i><span><b>Menu</b></span></a>
    </div>   */}









  <div className="card card-style mb-0 m-0" style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>
  {/* <h4 className="font-28 text-center color-theme font-800 pt-3 mt-3">AppKit</h4>  */}
  <p className="boxed-text-l mb-4">

    &copy 

    Developed By O3DEVELOPERS
  </p>
  {/* <div className="text-center mb-4">
  <a href="#" className="icon icon-xs rounded-sm shadow-l mr-1 bg-facebook"><i className="fab fa-facebook-f"></i></a>
  <a href="#" className="icon icon-xs rounded-sm shadow-l mr-1 bg-twitter"><i className="fab fa-twitter"></i></a>
  <a href="#" className="icon icon-xs rounded-sm shadow-l mr-1 bg-phone"><i className="fa fa-phone"></i></a>
  <a href="#" data-menu="menu-share" className="icon icon-xs rounded-sm mr-1 shadow-l bg-red-dark"><i className="fa fa-share-alt"></i></a>
  <a href="#" className="back-to-top icon icon-xs rounded-sm shadow-l bg-highlight color-white"><i className="fa fa-arrow-up"></i></a>
  </div> */}
  {/* <div className="divider mb-3"></div>
  <div className="row text-center mb-3 pl-3 pr-3">
  <a className="font-11 col-4" href="#">Privacy Policy</a>
  <a className="font-11 col-4" href="#">Terms of Service</a>
  <a className="font-11 col-4" href="#">Contact Support</a>
  </div> */}
  </div>


      </div>
  )
}

export default Footer
